
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'admin-bank-rates-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bankRatesListing'), [
          translate('bank'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_BANK_RATES_STORE);
      });

      await store.dispatch(Actions.GET_BANK_RATES);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('boubyanCustomer'),
          key: 'percentage',
          sortable: true,
        },
        {
          name: translate('nonBoubyanCustomer'),
          key: 'notClientPercentage',
          sortable: true,
        },
        {
          name: translate('repaymentPeriod'),
          key: 'repaymentPeriod',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const editBank = (id) => {
        router.push({ name: 'admin-bank-rates-overview', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_BANK_RATE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_BANK_RATES);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.bankRatesList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.bankRatesListCount),
        pageChanged,
        goTo,
        editBank,
        loading,
        can,
      };
    },
  });
